<template>
    <div>
        <card-header title="Registration Days" icon="fa-calendar-check"/>
        <card-body>
            <card-list>
                <subheader-list-item title="Trip Days" icon="fa-calendar-alt"/>
                <day-toggle-list-item
                    :card="card"
                    :day="day"
                    v-for="day in days"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from '../../TIER/components/CardHeader';
    import CardList from '../../TIER/components/CardList';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import SubheaderListItem from '../../TIER/components/SubheaderListItem';
    import CardBody from '../../TIER/components/CardBody';

    import {client as http} from '../../http_client';
    import async from 'async';

    import DayToggleListItem from "@/components/DayToggleListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {DayToggleListItem, CardBody, SubheaderListItem, CardToolbar, CardList, CardHeader},
        data: function () {
            return {
                registration: {},
                days: [],
                apiUrl: (location.hostname === 'localhost') ? 'http://localhost:3000/' : 'https://portal.tennisventures.net:3000/',
            };
        },
        methods: {
            loadEvents: function (force) {
                this.$emit('loading', true);
                async.series([
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {force}).then(response => {
                            response.data.registration_days = (response.data.registration_days) ? response.data.registration_days.split(',') : [];
                            this.registration = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/trips/' + this.props.tripId + '/days/0/30', {force}).then(response => {
                            for (let i = 0; i < response.data.days.length; i++) {
                                let day = response.data.days[i];
                                day.active = (this.registration.registration_days.includes(day.id.toString()));
                            }
                            this.days = response.data.days;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            reload: function () {
                this.loadEvents(true);
            }
        },
        watch: {
            'props.dayId': function () {
                this.loadEvents();
            },
            'days': {
                deep: true,
                handler () {
                    let regDays = [];
                    for (let i = 0; i < this.days.length; i++) {
                        const day = this.days[i];
                        if (day.active) regDays.push(day.id);
                    }

                    http.put('/api/registrations/' + this.props.registrationId, {trip_id: this.registration.trip_id, user_id: this.registration.user_id, type: this.registration.type, registration_days: regDays.join(',')}, {force: true}).then(response => {

                    }).catch(err => {

                    });
                },
            }
        },
        mounted() {
            this.loadEvents();
        }
    };
</script>

<style lang="scss" scoped>

</style>
