<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-calendar-day" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{day.title}}</div>
            <div class="meta" v-if="day.day_on">{{day.day_on | moment('dddd, MMMM Do, YYYY')}}</div>

            <div class="mt-3">
                <b-switch size="is-small" v-model="day.active">Participant not participating</b-switch>

            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'DayToggleListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            day: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }
</style>
